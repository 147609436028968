<template>
  <div>
    <div v-if="showTip">转换中,请您稍后打开.....</div>
    <div id="J_prismPlayer"></div>
  </div>
</template>

<script>
//导出aliplayer
import "aliyun-aliplayer";
// 导出样式
import "aliyun-aliplayer/dist/skins/default/aliplayer-min.css";
import { getInfo } from "@/api/cms/attach.js";

export default {
  data() {
    return {
      showTip: false,
    };
  },
  methods: {
    getInfo() {
      getInfo(this.$route.params.eid).then((res) => {
        if (res) {
          this.play(res);
        } else {
          this.showTip = true;
        }
      });
    },
    play(source) {
      var player = new Aliplayer(
        {
          id: "J_prismPlayer",
          source: source,
          width: "100%",
          height: "650px",
          autoplay: false,
          isLive: false,
          rePlay: false,
          // "playsinline": true,
          preload: true,
          controlBarVisibility: "hover",
          useH5Prism: true,
          encryptType: 1,
          mediaType: "video",
        },
        function (player) {
          console.log("The player is created.");
        }
      );
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style></style>
